import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { apiResetPassword } from "../../apis/auth";
import InputText from "../common/input-text";

const formSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  newPassword: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters long")
    .matches(
      /[A-Z]/,
      "Password must contain at least one uppercase (A-Z) character"
    )
    .matches(
      /[a-z]/,
      "Password must contain at least one lowercase (a-z) character"
    )
    .matches(
      /[0-9]/,
      "Password must contain at least one numeruc (0-9) character"
    )
    .matches(
      /[^A-Za-z0-9]/,
      "Password must contain at least one special (symbol) character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

const formDefaults = {
  email: "",
  newPassword: "",
  confirmPassword: "",
};

const NewUser = () => {
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Row className="w-100">
      <Col xs={12} md={9} lg={6} className="mx-auto p-2">
        <Card className="w-100">
          <CardBody className="p-4">
            <div className="text-center">
              <img
                src="/images/lumensol-v.png"
                alt="iLumen8 Reporting"
                className="mb-4"
              />
            </div>
            {reset ? (
              <>
                <div className="text-center">
                  <h1>New User</h1>
                  <p>Now please log in.</p>
                  <p>
                    <Link to="/login" className="btn btn-primary">
                      Return to Login
                    </Link>
                  </p>
                </div>
              </>
            ) : (
              <>
                <h1>New User</h1>
                <Formik
                  initialValues={formDefaults}
                  validationSchema={formSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    setError(false);
                    if (
                      await apiResetPassword(values.email, values.newPassword)
                    ) {
                      setReset(true);
                    } else {
                      setError(true);
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className="text-left">
                      <InputText
                        name="email"
                        type="text"
                        label="Email Address"
                      />
                      <InputText
                        name="newPassword"
                        type="password"
                        label="New Password"
                      />
                      <InputText
                        name="confirmPassword"
                        type="password"
                        label="Confirm Password"
                      />

                      <Button
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Set Password
                      </Button>
                      {error && (
                        <div className="validation-error d-inline ml-2">
                          Reset Failed
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
                <div className="text-right">
                  <Link to="/login" className="btn btn-link">
                    Return to Login
                  </Link>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default NewUser;
