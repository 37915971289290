import React, { useState, useMemo, useEffect } from "react";
import LoadingContext from "../contexts/loading-context";
import axios from "axios";
import { useCallback } from "react";

const LoadingProvider = ({ children }) => {
  
  const inc = useCallback(() => {
    toggleLoading((prevState) => {
      return {
        ...prevState,
        loadingCount: prevState.loadingCount + 1,
      };
    });
  }, []);

  const dec = useCallback(() => {
    toggleLoading((prevState) => {
      return {
        ...prevState,
        loadingCount: prevState.loadingCount > 0 ? prevState.loadingCount - 1 : 0,
      };
    });
  }, []);

  const interceptors = useMemo(
    () => ({
      request: (config) => {
        inc();
        return config;
      },
      response: (response) => {
        dec();
        return response;
      },
      error: (error) => {
        dec();
        return Promise.reject(error);
      },
    }),
    [inc, dec]
  );

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = axios.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const resInterceptor = axios.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);


  const loadingState = {
    loadingCount: 0,
  };

  const [loading, toggleLoading] = useState(loadingState);

  return <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>;
};

export default LoadingProvider;
