import React from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import AuthLayout from "./auth-layout";
import PreauthLayout from "./preauth-layout";
import Home from "./home";
import Logout from "../auth/logout";
import ClientError from "../status/400";
import ServerError from "../status/500";

const Layout = () => {
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/400" exact component={ClientError} />
        <Route path="/500" exact component={ServerError} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/secure/" component={AuthLayout} />
        <Route component={PreauthLayout} />
      </Switch>
    </Router>
  );
};

export default withRouter(Layout);
