import React, { useContext, useEffect, useState } from "react";
import { apiGroupReportEmbedTokenGet } from "../../apis/power-bi";
import { UserContext } from "../../contexts/user-context";
import PowerBIEmbed from "../common/powerbi-embed";

const GroupReport = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [embedToken, setEmbedToken] = useState(undefined);
  const [embedUrl, setEmbedUrl] = useState(undefined);
  const [pageName, setPageName] = useState(undefined);
  const [id, setId] = useState(undefined);
  const { contextClient } = useContext(UserContext);

  useEffect(() => {
    const loadData = async (reportId) => {
      var data = await apiGroupReportEmbedTokenGet(contextClient.groupId, reportId, contextClient.userId, contextClient.clientId);
      setEmbedToken(data.token);
      setEmbedUrl(data.embedUrl);
      setLoading(false);
    };

    if (contextClient?.userId && match.params && match.params.id) {
      setId(match.params.id);
      if (match.params.pageName) {
        setPageName(match.params.pageName);
      } else {
        setPageName(undefined);
      }
      loadData(match.params.id);
    }
  }, [contextClient, match.params]);

  return (
    <>
      {!loading && (
        <div className="m-n3">
          <PowerBIEmbed embedType="report" id={id} embedUrl={embedUrl} accessToken={embedToken} idleTimer pageName={pageName} />
        </div>
      )}
    </>
  );
};

export default GroupReport;
