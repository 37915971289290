import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { apiLogin, apiPreLogin } from "../../apis/auth";
import InputText from "../common/input-text";

const formSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  password: Yup.string().required("Required"),
});

const formDefaults = {
  email: "",
  password: "",
};

const Login = ({ history, location }) => {
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const doPreLogin = async () => {
      var fromLogout = location.state && location.state.redirectFromLogout;
      if (!fromLogout) {
        await apiPreLogin();
      }
    };
    doPreLogin();
  }, [location.state]);

  return (
    <Row className="w-100">
      <Col xs={12} md={9} lg={6} className="mx-auto p-2">
        <Card className="w-100">
          <CardBody className="p-4">
            <div className="text-center">
              <img src="/images/lumensol-v.png" alt="iLumen8 Reporting" className="mb-4" />
            </div>
            <h1>Login</h1>
            <Formik
              initialValues={formDefaults}
              validationSchema={formSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setError(undefined);

                try {
                  await apiLogin(values.email, values.password);
                  history.push("/");
                  return;
                } catch (error) {
                  console.error("ALEX:", error);
                  setError(error);
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="text-left">
                  <InputText name="email" type="text" label="Email Address" />
                  <InputText name="password" type="password" label="Password" />

                  <Button color="primary" type="submit" disabled={isSubmitting}>
                    Log In
                  </Button>
                  {error && (
                    <>
                      {error.response?.data === "PasswordExpired" ? (
                        <Card className="mt-4 mb-2 p-3 rounded-lg border-white" style={{ backgroundColor: "rgba(254 226 226)" }}>
                          <h5>Password Expired</h5>
                          <p className="my-0">
                            It has been over 90 days since you last reset your password for your iLumen8 account. You are required to reset
                            your password to gain access. An email has been sent containing a link to reset your password.
                          </p>
                        </Card>
                      ) : (
                        <div className="validation-error d-inline ml-2">Login Failed</div>
                      )}
                    </>
                  )}
                  <div className="text-right">
                    <Link to="/password-forgot">Forgotten Password?</Link>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
