import axios from "axios";

export const apiReportUsersGet = async (clientId, reportId) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/ReportUsers",
    params: {
      clientId,
      reportId,
    },
  });

  return result.data;
};

export const apiReportUserUpdate = async (clientId, reportId, reportUserIds, emailReportUserIds) => {
  try {
    await axios({
      method: "post",
      url: "/api/v1/ReportUser",
      data: { clientId, reportId, reportUserIds, emailReportUserIds },
    });

    return true;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return err.response.data;
    }

    console.error(err);
    return false;
  }
};
