import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { configureAxios } from "./apis/axios-helper";
import "./assets/scss/site.scss";
import Layout from "./components/layouts/layout";
import LoadingProvider from './contexts/loading-provider'
import Spinner from "./components/common/spinner";

configureAxios();

const App = () => (
  <>
    <LoadingProvider>
    <Spinner />
      <Router>
        <Layout />
      </Router>
    </LoadingProvider>
  </>
);

export default App;
