export const sortByProp = (prop) => (a, b) => {
  if (!a && !b) {
    return 0;
  }
  if (!a && b) {
    return 1;
  }
  if (a && !b) {
    return -1;
  }

  if (a[prop] === b[prop]) {
    return 0;
  }
  //if first case of a and b are unequal return based on case
  if (a[prop] !== b[prop] && a[prop]?.toLocaleLowerCase() === b[prop]?.toLocaleLowerCase()) {
    return a[prop] < b[prop] ? 1 : -1;
  }
  //Now return 0 if both are equal in lower case else return 1 or -1 depending on comparison below
  if (a[prop]?.toLocaleLowerCase() === b[prop]?.toLocaleLowerCase()) {
    return 0;
  }
  return a[prop]?.toLocaleLowerCase() > b[prop]?.toLocaleLowerCase() ? 1 : -1;
};
