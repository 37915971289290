import React from "react";
import { footerLogoHeight } from "../../config";

const Footer = () => {
  return (
 <footer className="footer">
      <div className="p-2">
          <img src="/images/ilumen8.png" alt="iLumen8 Reporting" height={footerLogoHeight} />
      </div>
    </footer>    
  );
};

export default Footer;