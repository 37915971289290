export const productName = "iLumen8";
export const logoHeight = 50;
export const footerLogoHeight = 80;

export const environments = {
  Localhost: "Localhost",
  Development: "Development",
  Test: "Test",
  Production: "Production",
};

export const authExpiryCookieName = () => {
    const environment = getEnvironment().replace("Production","").toLowerCase();
    const product = productName.toLowerCase();
    return `${product}${environment && `.${environment}`}.authexpiry`;
}

export const apiUrl = () => {
  switch (getEnvironment()) {
    case environments.Localhost: //No override required, proxy in package.json will deal
      return undefined;

    case environments.Production:
      return "https://api.lumensol.co.uk";
    case environments.Test:
      return "https://api.test.lumensol.co.uk";
    case environments.Development:
    default:
      return "https://api.dev.lumensol.co.uk";
  }
};

export const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;

  switch (hostname) {
    case "localhost":
      return environments.Localhost;

    case "ls-prod-cdn.azureedge.net":
    case "app.lumensol.co.uk":
    case "a2d.lumensol.co.uk":
      return environments.Production;

    case "ls-test-cdn.azureedge.net":
    case "app.test.lumensol.co.uk":
    case "a2d.test.lumensol.co.uk":
      return environments.Test;

    default:
      return environments.Development;
  }
};
