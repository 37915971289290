import React, { useEffect, useState} from "react";
import { Redirect } from "react-router-dom";
import { apiLogout } from "../../apis/auth";

const Logout = () => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    const doLogout = async () => {
      await apiLogout();
      setDone(true);
    };

    doLogout();
  }, []);

  if (done) {
    return (
      <Redirect
        to={{ pathname: "/login", state: { redirectFromLogout: true } }}
      />
    );
  }

  return null;
};

export default Logout;
