import { useField } from "formik";
import React from "react";
import { CustomInput, FormGroup, Button } from "reactstrap";

const InputFile = ({ label, onClear, onUpload, ...props }) => {
  const [field, meta, helper] = useField(props);

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      helper.setValue(e.target.files[0]);
      e.target.value = null;
      if (onUpload) {
        onUpload();
      }
    }
  };

  const handleClear = () => {
    helper.setValue(undefined);
    if (onClear) {
      onClear();
    }
  };

  return (
    <FormGroup>
      <label htmlFor={field.name}>{label}</label>
      <CustomInput type="file" id={field.name} name={field.name} className="shadow-sm" onChange={handleChange} />
      {meta.touched && meta.error && <div className="validation-error">{meta.error}</div>}
      {field.value && (
        <Button block type="button" size="sm" onClick={handleClear} className="mt-2">
          Remove
        </Button>
      )}
    </FormGroup>
  );
};

export default InputFile;
