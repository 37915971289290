import axios from "axios";

export const apiPreLogin = async () => {
  await axios({
    method: "post",
    url: "/api/v1/PreLogin",
  });
};

export const apiLogin = async (emailAddress, password) => {
  await axios({
    method: "post",
    url: "/api/v1/Login",
    data: {
      emailAddress,
      password,
    },
  });
};

export const apiLogout = async () => {
  await axios({
    method: "post",
    url: "/api/v1/Logout",
  });
};

export const apiSendResetPasswordEmail = async (emailAddress) => {
  await axios({
    method: "post",
    url: "/api/v1/PasswordForgot",
    data: {
      emailAddress,
    },
  });
};

export const apiResetPassword = async (emailAddress, newPassword) => {
  const params = new URLSearchParams(window.location.search);
  const resetToken = params.get("code");

  try {
    await axios({
      method: "post",
      url: "/api/v1/PasswordReset",
      data: {
        emailAddress,
        newPassword,
        resetToken,
      },
    });

    return true;
  } catch {
    return false;
  }
};
