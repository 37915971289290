import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from "reactstrap";
import { apiClientsGet } from "../../apis/client";
import { sortByProp } from "../../helpers/sort";
import { tableIcons, tableOptions } from "../common/material-table-icons";

const ClientList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      setData(await apiClientsGet());
      setLoading(false);
    };
    loadData();
  }, []);

  const columns = [
    { title: "Client Name", field: "companyName", defaultSort: "asc", customSort: sortByProp("companyName") },
    {
      title: "Colour Scheme",
      render: (rowData) => (
        <div
          className="p-2 d-inline-block"
          style={{
            color: rowData.colour,
            backgroundColor: rowData.backgroundColour,
          }}
        >
          Sample
        </div>
      ),
      sorting: false,
    },
    {
      title: "",
      render: (rowData) => (
        <div className="text-right">
          <UncontrolledButtonDropdown>
            <Link to={`/secure/client/${rowData.id}`} className="btn btn-sm btn-primary d-flex align-items-center">
              Edit
            </Link>
            <DropdownToggle split color="primary" />
            <DropdownMenu>
              <Link to={`/secure/client/${rowData.id}/reports`} className="text-decoration-none">
                <DropdownItem className="text-primary">Reports</DropdownItem>
              </Link>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      sorting: false,
    },
  ];

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <>
          <Row>
            <Col xs={6}>
              <h2>Clients</h2>
            </Col>
            <Col xs={6} className="text-right my-auto">
              <Link to="/secure/client" className="btn btn-sm btn-primary">
                Add Client
              </Link>
            </Col>
          </Row>

          <MaterialTable icons={tableIcons} columns={columns} data={data} title="" options={tableOptions} />
        </>
      )}
    </>
  );
};

export default ClientList;
