import { useField, useFormikContext } from "formik";
import React from "react";
import { CustomInput, FormGroup } from "reactstrap";

const InputToggle = ({ label, onChange, alsoOn, alsoOff, disabled, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    field.onChange(e);
    if (alsoOn && e.target.checked) {
      setFieldValue(alsoOn, true);
    }

    if (alsoOff && !e.target.checked) {
      setFieldValue(alsoOff, false);
    }
  };

  return (
    <FormGroup className="mb-0">
      <CustomInput
        type="switch"
        {...field}
        id={field.name}
        label={label}
        checked={field.value || false}
        onChange={handleChange}
        disabled={disabled || false}
      ></CustomInput>
      {meta.error && <div className="validation-error">{meta.error}</div>}
    </FormGroup>
  );
};

export default InputToggle;
