import axios from "axios";

export const apiClientsGet = async () => {
  const result = await axios({
    method: "get",
    url: "/api/v1/Clients",
  });

  return result.data;
};

export const apiClientGet = async (id) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/Client",
    params: { id },
  });

  if (!result.data.groupId){
    result.data.groupId = "";
  }

  return result.data;
};

export const apiClientUpdate = async (id, companyName, colour, backgroundColour, logoBase64Image, deleteLogo, groupId, domainId, dashboardIds, reportIds) => {
  try {
    await axios({
      method: "post",
      url: "/api/v1/Client",
      data: { id, companyName, colour, backgroundColour, logoBase64Image, deleteLogo, groupId, domainId, dashboardIds, reportIds },
    });

    return true;
  } catch (err) {

    if (err.response && err.response.status === 400) {
      return err.response.data;
    }

    console.error(err);
    return false;
  }
};

export const apiDomainsGet = async () => {
  const result = await axios({
    method: "get",
    url: "/api/v1/Domains",
  });

  return result.data;
};
