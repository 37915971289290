import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { logoHeight } from "../../config";
import { UserContext } from "../../contexts/user-context";

const DisplayLogo = ({ image, companyName, className = "mr-2", height = logoHeight }) => {
  const { contextBrand } = useContext(UserContext);

  if (!image) {
    return (
      <>
        <span className="py-auto d-inline-block" style={{ fontSize: 20, lineHeight: `${logoHeight}px`, color: contextBrand.colour }}>
          {companyName}
        </span>
      </>
    );
  }

  if (typeof image === "string" && image.includes("base64")) {
    return (
      <Link to="/">
        <img src={image} alt="Logo" height={height} className={className} />
      </Link>
    );
  }

  return (
    <Link to="/">
      <img src={URL.createObjectURL(image)} alt="Logo" height={height} className={className} />
    </Link>
  );
};

export default DisplayLogo;
