import { useField } from "formik";
import React from "react";
import { FormGroup } from "reactstrap";

const InputText = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormGroup>
      <label htmlFor={field.name}>{label}</label>
      <input {...field} id={field.name} {...props} className="form-control shadow-sm" />
      {meta.touched && meta.error && <div className="validation-error">{meta.error}</div>}
    </FormGroup>
  );
};

export default InputText;
