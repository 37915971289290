import React, { useContext } from "react";
import LoadingContext from "../../contexts/loading-context";
import { Spinner as BSSpinner } from "reactstrap";

const Spinner = () => {
  const { loadingCount } = useContext(LoadingContext);

  return (
    <>
      {loadingCount > 0 && (
        <div
          className="position-absolute bg-light min-vw-100 min-vh-100 d-flex align-items-center justify-content-center" style={{ zIndex: 9999, opacity: 0.75, top: 0 }}>
          <BSSpinner type="grow" color="warning" className="my-auto" />
        </div>
      )}
    </>
  );
};

export default Spinner;