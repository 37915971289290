import swal from "@sweetalert/with-react";
import { factories, models, service } from "powerbi-client";
import { Permissions, TokenType } from "powerbi-models";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { apiPing } from "../../apis/power-bi";
import { UserContext } from "../../contexts/user-context";
import useInterval from "../../hooks/use-interval";
import useWindowSize from "../../hooks/use-window-size";
import { logoHeight } from "../../config";

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

const navHeight = logoHeight + 16;

const PowerBIEmbed = ({
  embedType,
  id,
  embedUrl,
  accessToken,
  filterPaneEnabled = false,
  navContentPaneEnabled,
  pageName,
  history,
  idleTimer,
}) => {
  const component = useRef(null);
  const rootElement = useRef(null);
  const [config, setConfig] = useState({ type: "report" });
  const size = useWindowSize();
  const [height, setHeight] = useState(window.innerHeight - navHeight);
  const [newHeight, setNewHeight] = useState(window.innerHeight - navHeight);
  const [mobile, setMobile] = useState(window.innerWidth < 576);
  const swalTimer = useRef(null);
  const redirectTimer = useRef(null);
  const { IdleWarningMillisecondsGet, IdleRedirectMillisecondsGet, settings } = useContext(UserContext);

  const sortEvents = useCallback(
    (add) => {
      try {
        component.current.off("rendered");
        component.current.off("error");
        config.type === "dashboard" && component.current.off("tileClicked");
      } catch (ex) {
        console.log("EVENT OFF ERROR");
        console.log(ex);
      }

      if (add) {
        try {
          component.current.on("rendered", async function () {
            await apiPing();
          });
          component.current.on("error", function (e) {
            if (e.detail && e.detail.message && e.detail.message !== "mobileLayoutError") {
              history.push("/secure/dashboard");
            }
          });
          config.type === "dashboard" &&
            component.current.on("tileClicked", function (event) {
              if (event.detail && event.detail.event === "tileClicked") {
                const url = new URL(event.detail.reportEmbedUrl);
                const reportId = url.searchParams.get("reportId");

                const reports = settings.reports;
                const report = reports.find((x) => x.id === reportId);

                if (report) {
                  const pageName = event.detail.pageName;

                  history.push(`/secure/group-report/${reportId}/${pageName}`);
                } else {
                  swal(
                    <div>
                      <h4>No Access To Report</h4>
                    </div>
                  );
                }
              }
            });
        } catch (ex) {
          console.log("EVENT ON ERROR");
          console.log(ex);
        }
      }
    },
    [history, config.type, settings]
  );

  const clearTimers = useCallback(() => {
    if (swalTimer.current) {
      clearTimeout(swalTimer.current);
      swalTimer.current = undefined;
    }
    if (redirectTimer.current) {
      clearTimeout(redirectTimer.current);
      redirectTimer.current = undefined;
    }
  }, [swalTimer, redirectTimer]);

  const startTimers = useCallback(() => {
    if (idleTimer) {
      clearTimers();

      if (IdleWarningMillisecondsGet()) {
        swalTimer.current = setTimeout(() => {
          swal(
            <div>
              <h4>Still viewing this report?</h4>
              <p>Click 'OK' to continue viewing the report otherwise you will be redirected to your dashboard in 30 seconds.</p>
            </div>
          ).then(async (value) => {
            if (value) {
              await apiPing();
              startTimers();
            }
          });
        }, IdleWarningMillisecondsGet());
      }

      if (IdleWarningMillisecondsGet()) {
        redirectTimer.current = setTimeout(() => {
          clearTimers();
          swal.close();
          history.push("/secure/dashboard");
        }, IdleRedirectMillisecondsGet());
      }
    }
  }, [idleTimer, history, clearTimers, IdleWarningMillisecondsGet, IdleRedirectMillisecondsGet]);

  useEffect(() => {
    startTimers();

    return clearTimers;
  }, [startTimers, clearTimers]);

  useInterval(() => {
    if (height !== newHeight) {
      setHeight(newHeight);
    }
  }, 2000);

  useEffect(() => {
    size.then((r) => {
      setNewHeight(r.height - navHeight);
      setMobile(r.width < 576);
    });
  }, [size]);

  const embed = useCallback(
    (config) => {
      try {
        component.current = powerbi.embed(rootElement.current, config);
      } catch (ex) {
        console.log("EMBED ERROR");
        console.log(ex);
      }

      sortEvents(true);

      return component.current;
    },
    [sortEvents]
  );

  useEffect(() => {
    try {
      if (component.current) {
        sortEvents(false);
        powerbi.reset(rootElement.current);
        embed(config);
      }
    } catch (ex) {
      console.log("MOBILE ERROR");
      console.log(ex);
    }
  }, [mobile, embed, config, sortEvents]);

  useEffect(() => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        id,
        embedUrl,
        accessToken,
        pageName,
        settings: {
          filterPaneEnabled,
          navContentPaneEnabled,
          layoutType: mobile ? models.LayoutType.MobilePortrait : models.LayoutType.Master,
          hideErrors: true,
        },
        permissions: Permissions.Read,
        tokenType: TokenType.Embed,
        type: embedType || "report",
      };
    });
  }, [embedType, mobile, id, embedUrl, accessToken, height, filterPaneEnabled, navContentPaneEnabled, pageName]);

  useEffect(() => {
    function validateConfig(config) {
      try {
        const errors = models.validateReportLoad(config);

        if (errors) {
          console.error(errors);
        }

        return errors === undefined;
      } catch (ex) {
        console.log("VALIDATE CONFIG ERROR");
        console.log(ex);
      }
    }

    if (!config.accessToken) {
      return;
    }

    if (validateConfig(config)) {
      embed(config);
    }
  }, [config, embed]);

  useEffect(() => {
    const currentRootElement = rootElement.current;

    return () => {
      try {
        if (component.current) {
          sortEvents(false);
        }
        powerbi.reset(currentRootElement);
        component.current = null;
      } catch (ex) {
        console.log("UNLOAD ERROR");
        console.log(ex);
      }
    };
  }, [sortEvents]);

  return (
    <div
      className="powerbi-frame"
      ref={(el) => {
        rootElement.current = el;
      }}
      style={{ height }}
    />
  );
};

export default withRouter(PowerBIEmbed);
