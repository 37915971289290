import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../../contexts/user-context";

const SecureHome = () => {
  const { settings } = useContext(UserContext);

  if (!settings.userId) {
    return null;
  }

  if (settings.isAdmin) {
    return <Redirect to="/secure/clients" />;
  }

  return <Redirect to="/secure/dashboard" />;
};

export default SecureHome;
