import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { footerLogoHeight } from "../../config";
import { UserProvider } from "../../contexts/user-context";
import ClientEdit from "../clients/client-edit";
import ClientList from "../clients/client-list";
import Footer from "../common/footer";
import Dashboard from "../dashboard/dashboard";
import GroupDashboard from "../dashboard/group-dashboard";
import GroupReport from "../dashboard/group-report";
import MainNav from "../nav/main-nav";
import NotFound from "../not-found";
import ReportAssign from "../reports/report-assign";
import ReportList from "../reports/report-list";
import UserAdd from "../users/user-add";
import UserAssign from "../users/user-assign";
import UserList from "../users/user-list";
import AdminRoute from "./admin-route";
import SecureHome from "./secure-home";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["HelveticaNeue", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
  },
});

const AuthLayout = ({ location, match }) => {
  const defaultMargin = 8;
  const showFooter = () => {
    return location.pathname.indexOf("/secure/group-report/") === -1 && location.pathname.indexOf("/secure/group-dashboard/") === -1;
  };

  return (
    <UserProvider>
      <MainNav />
      <div
        className="p-2"
        style={{
          margin: defaultMargin,
          marginBottom: showFooter() ? footerLogoHeight + defaultMargin + 1 + defaultMargin : defaultMargin,
        }}
      >
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/secure" exact component={SecureHome} />
            <Route path="/secure/dashboard" exact component={Dashboard} />
            <Route path="/secure/group-report/:id/:pageName" exact component={GroupReport} />
            <Route path="/secure/group-report/:id" exact component={GroupReport} />
            <Route path="/secure/group-dashboard/:id" exact component={GroupDashboard} />
            <AdminRoute path="/secure/client/:id" exact component={ClientEdit} />
            <AdminRoute path="/secure/client" exact component={ClientEdit} />
            <AdminRoute path="/secure/clients" exact component={ClientList} />
            <AdminRoute path="/secure/users" exact component={UserList} />
            <AdminRoute path="/secure/user/:userId" exact component={UserAdd} />
            <AdminRoute path="/secure/user" exact component={UserAdd} />
            <AdminRoute path="/secure/client/:clientId/user/:userId" exact component={UserAssign} />
            <AdminRoute path="/secure/client/:clientId/reports" exact component={ReportList} />
            <AdminRoute path="/secure/client/:clientId/report/:reportId" exact component={ReportAssign} />
            <Route component={NotFound} />
          </Switch>
        </ThemeProvider>
      </div>
      {showFooter() && <Footer />}
    </UserProvider>
  );
};

export default withRouter(AuthLayout);
