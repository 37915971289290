import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { apiClientGet } from "../../apis/client";
import { apiGroupDashboardsGet, apiGroupReportsGet } from "../../apis/power-bi";
import { apiUserAssignmentsGet, apiUserAssignmentsPatch } from "../../apis/users";
import { InputText, InputToggle } from "../common/inputs";

const formSchema = Yup.object().shape({
  //displayName: Yup.string().required("Required"),
});

const UserAssign = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPowerBI, setLoadingPowerBI] = useState(false);
  const [clientId, setClientId] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [userId, setUserId] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [dashboards, setDashboards] = useState(null);
  const [reports, setReports] = useState(null);

  const loadGroupData = async (groupId) => {
    setLoadingPowerBI(true);
    setDashboards(null);
    setReports(null);

    if (groupId) {
      let [dashboardsResult, reportsResult] = await Promise.all([apiGroupDashboardsGet(groupId), apiGroupReportsGet(groupId)]);
      setDashboards(dashboardsResult);
      setReports(reportsResult);
    }

    setLoadingPowerBI(false);
  };

  useEffect(() => {
    const loadData = async (userId, clientId) => {
      const result = await apiUserAssignmentsGet(userId, clientId);

      result.dashboards.forEach((dashboard) => {
        if (dashboard.setForClient) {
          result[`clientdashboard_${dashboard.dashboardId}`] = true;
        } else {
          result[`dashboard_${dashboard.dashboardId}`] = true;
        }
      });

      result.reports.forEach((report) => {
        if (report.setForClient) {
          result[`clientreport_${report.reportId}`] = true;
        } else {
          result[`report_${report.reportId}`] = true;
        }
      });

      result.emailReports.forEach((report) => {
        result[`emailreport_${report.reportId}`] = true;
      });

      setData(result);

      if (clientId) {
        const clientResult = await apiClientGet(clientId);
        setClient(clientResult);
        await loadGroupData(clientResult.groupId);
      }

      setLoading(false);
    };

    if (!match.params.clientId || !match.params.userId) {
      history.push("/secure/users");
    }

    loadData(match.params.userId, match.params.clientId);
    setUserId(match.params.userId);
    setClientId(match.params.clientId);
  }, [match, history]);

  const getDashboardAndReports = (values) => {
    const dashboardIds = [];
    const reportIds = [];
    const emailReportIds = [];

    for (let [key, value] of Object.entries(values)) {
      if (key.includes("_") && value) {
        const parts = key.split("_");
        const type = parts[0];
        const id = parts[1];

        switch (type) {
          case "dashboard":
            dashboardIds.push(id);
            break;
          case "report":
            reportIds.push(id);
            break;
          case "emailreport":
            emailReportIds.push(id);
            break;
          default:
            break;
        }
      }
    }

    return { dashboardIds, reportIds, emailReportIds };
  };

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <>
          <h1>Assign User Dashboards & Reports</h1>
          <Formik
            initialValues={data}
            validationSchema={formSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setError(undefined);

              let { dashboardIds, reportIds, emailReportIds } = getDashboardAndReports(values);

              const result = await apiUserAssignmentsPatch(userId, clientId, dashboardIds, reportIds, emailReportIds);
              if (typeof result === "boolean") {
                history.push("/secure/users");
              }

              setError(result.errors.map((x) => x.errorMessage).join(","));
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="text-left">
                {clientId && (
                  <>
                    <InputText name="displayName" type="text" label="Name" disabled />
                    <InputText name="email" type="text" label="Email Address" disabled />
                    <InputText name="clientId" type="text" label="Client" value={client.companyName} disabled />

                    <h5>Power BI Permissions</h5>

                    {!loadingPowerBI && (
                      <>
                        <p>
                          Please select the Dashboards and Reports that this User will have access to. This is in addition to any Dashboards
                          and Reports assigned on the Client screen.
                        </p>
                        <div className="mb-2">
                          <Row>
                            <Col>
                              <u>Dashboard</u>
                            </Col>
                            <Col xs="2" md={1} className="text-center">
                              Client
                            </Col>
                            <Col xs="2" md={1} className="text-center">
                              View
                            </Col>
                            <Col xs="2" md={1} className="text-center"></Col>
                          </Row>
                          {(!dashboards || dashboards.length === 0) && <div>None Available</div>}
                          {dashboards &&
                            dashboards.map((item, idx) => (
                              <Row key={idx}>
                                <Col>{item.displayName}</Col>
                                <Col xs="2" md={1} className="text-center">
                                  <InputToggle name={`clientdashboard_${item.id}`} disabled={true} />
                                </Col>
                                <Col xs="2" md={1} className="text-center">
                                  <InputToggle name={`dashboard_${item.id}`} />
                                </Col>
                                <Col xs="2" md={1} className="text-center"></Col>
                              </Row>
                            ))}
                          <Row className="mt-2">
                            <Col>
                              <u>Reports</u>
                            </Col>
                            <Col xs="2" md={1} className="text-center">
                              Client
                            </Col>
                            <Col xs="2" md={1} className="text-center">
                              View
                            </Col>
                            <Col xs="2" md={1} className="text-center">
                              Email
                            </Col>
                          </Row>
                          {(!reports || reports.length === 0) && <div>None Available</div>}
                          {reports &&
                            reports.map((item, idx) => (
                              <Row key={idx}>
                                <Col>{item.name}</Col>
                                <Col xs="2" md={1} className="text-center">
                                  <InputToggle name={`clientreport_${item.id}`} disabled={true} />
                                </Col>
                                <Col xs="2" md={1} className="text-center">
                                  <InputToggle name={`report_${item.id}`} />
                                </Col>
                                <Col xs="2" md={1} className="text-center">
                                  <InputToggle name={`emailreport_${item.id}`} />
                                </Col>
                              </Row>
                            ))}
                        </div>
                      </>
                    )}
                  </>
                )}

                <Button color="primary" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
                {clientId && (
                  <Link to={`/secure/users`} className="ml-1 btn btn-link">
                    Back
                  </Link>
                )}
                {error && <div className="validation-error d-inline ml-2">{error}</div>}
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default UserAssign;
