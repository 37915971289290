import { useField } from "formik";
import React from "react";
import { FormGroup, CustomInput } from "reactstrap";

const InputSelect = ({ label, items, optional, optionalValue = "", optionalText = "", valueField = "id", textField = "name", onChange, disabled, ...props }) => {
  const [field, meta] = useField(props);

  const handleChange = (e) => {
    field.onChange(e);

    if (onChange){
      onChange(e);
    }
  }

  return (
    <FormGroup>
      <label htmlFor={field.name}>{label}</label>
      <CustomInput type="select" name={field.name} onBlur={field.onBlur} id={field.name} className="shadow-sm" onChange={handleChange} value={field.value} disabled={disabled}>
        {optional && <option value={optionalValue}>{optionalText}</option>}
        {items &&
          items.map((item, idx) => (
            <option value={item[valueField]} key={idx}>
              {item[textField]}
            </option>
          ))}
      </CustomInput>
      {meta.error && <div className="validation-error">{meta.error}</div>}
    </FormGroup>
  );
};

export default InputSelect;
