import React, { useContext, useEffect, useState } from "react";
import { apiGroupDashboardEmbedTokenGet } from "../../apis/power-bi";
import { UserContext } from "../../contexts/user-context";
import PowerBIEmbed from "../common/powerbi-embed";

const GroupDashboard = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [embedToken, setEmbedToken] = useState(undefined);
  const [embedUrl, setEmbedUrl] = useState(undefined);
  const [id, setId] = useState(undefined);
  const { contextClient } = useContext(UserContext);

  useEffect(() => {
    const loadData = async (dashboardId) => {
      var data = await apiGroupDashboardEmbedTokenGet(contextClient.groupId, dashboardId, contextClient.userId, contextClient.clientId);
      setEmbedToken(data.token);
      setEmbedUrl(data.embedUrl);
      setLoading(false);
    };

    if (contextClient?.userId && match.params && match.params.id) {
      setId(match.params.id);
      loadData(match.params.id);
    }
  }, [contextClient, match.params]);

  return (
    <>
      {!loading && (
        <div className="m-n3">
          <PowerBIEmbed embedType="dashboard" id={id} embedUrl={embedUrl} accessToken={embedToken} />
        </div>
      )}
    </>
  );
};

export default GroupDashboard;
