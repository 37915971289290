import Cookies from "js-cookie";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router-dom";
import { authExpiryCookieName } from "../../config";

const Home = () => {
  const now = moment();
  const date = Cookies.get(authExpiryCookieName());
  const expiry = moment(date);

  var authExpiryValid = date && expiry.isAfter(now);

  if (authExpiryValid) {
    return <Redirect to="/secure" />;
  }

  return <Redirect to="/login" />;
};

export default Home;
