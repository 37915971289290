import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { apiClientGet } from "../../apis/client";
import { apiGroupReportsGet } from "../../apis/power-bi";
import { apiReportUsersGet, apiReportUserUpdate } from "../../apis/reports";
import { InputText, InputToggle } from "../common/inputs";

const ReportAssign = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPowerBI, setLoadingPowerBI] = useState(false);
  const [clientId, setClientId] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [reportId, setReportId] = useState(undefined);
  const [report, setReport] = useState(null);

  const loadGroupData = async (groupId, reportId) => {
    setLoadingPowerBI(true);
    setReport(null);

    if (groupId) {
      const reportsResult = await apiGroupReportsGet(groupId);

      setReport(reportsResult.find((x) => x.id === reportId));
    }

    setLoadingPowerBI(false);
  };

  useEffect(() => {
    const loadData = async (reportId, clientId) => {
      const result = await apiReportUsersGet(clientId, reportId);
      setUsers(result);

      const temp = {};

      result.forEach((item) => {
        temp[`report_${item.userId}`] = item.userReportId ? true : false;
        temp[`emailreport_${item.userId}`] = item.userEmailReportId ? true : false;
      });

      setData(temp);

      const clientResult = await apiClientGet(clientId);
      await loadGroupData(clientResult.groupId, reportId);

      setLoading(false);
    };

    loadData(match.params.reportId, match.params.clientId);
    setReportId(match.params.reportId);
    setClientId(match.params.clientId);
  }, [match]);

  const getReports = (values) => {
    const reportUserIds = [];
    const emailReportUserIds = [];

    for (let [key, value] of Object.entries(values)) {
      if (key.includes("_") && value) {
        const parts = key.split("_");
        const type = parts[0];
        const id = parts[1];

        switch (type) {
          case "report":
            reportUserIds.push(id);
            break;
          case "emailreport":
            emailReportUserIds.push(id);
            break;
          default:
            break;
        }
      }
    }

    return { reportUserIds, emailReportUserIds };
  };

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <>
          <h1>Assign Report</h1>
          <Formik
            initialValues={data}
            onSubmit={async (values, { setSubmitting }) => {
              setError(undefined);

              let { reportUserIds, emailReportUserIds } = getReports(values);

              const result = await apiReportUserUpdate(clientId, reportId, reportUserIds, emailReportUserIds);
              if (typeof result === "boolean") {
                history.push(`/secure/client/${clientId}/reports`);
                return;
              }

              setError(result.errors.map((x) => x.errorMessage).join(","));

              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values }) => (
              <Form className="text-left">
                <>
                  <InputText name="reportId" type="text" label="Report" value={report.name} disabled />

                  <h5>Power BI Permissions</h5>

                  {!loadingPowerBI && (
                    <>
                      <p>
                        Please select the Users that access this Report. This is in addition to any Reports assigned on the Client screen.
                      </p>
                      <div className="mb-2">
                        <Row className="mt-2">
                          <Col>
                            <u>Users</u>
                          </Col>
                          <Col xs="2" md={1} className="text-center">
                            View
                          </Col>
                          <Col xs="2" md={1} className="text-center">
                            Email
                          </Col>
                        </Row>
                        {users &&
                          users.map((item, idx) => (
                            <Row key={idx}>
                              <Col>{item.displayName}</Col>
                              <Col xs="2" md={1} className="text-center">
                                <InputToggle name={`report_${item.userId}`} />
                              </Col>
                              <Col xs="2" md={1} className="text-center">
                                <InputToggle name={`emailreport_${item.userId}`} />
                              </Col>
                            </Row>
                          ))}
                      </div>
                    </>
                  )}
                </>

                <Button color="primary" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
                {clientId && (
                  <Link to={`/secure/client/${clientId}/reports`} className="ml-1 btn btn-link">
                    Back
                  </Link>
                )}
                {error && <div className="validation-error d-inline ml-2">{error}</div>}
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default ReportAssign;
