import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { apiClientGet } from "../../apis/client";
import { apiGroupReportsGet } from "../../apis/power-bi";
import { tableIcons, tableOptions } from "../common/material-table-icons";

const ReportList = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPowerBI, setLoadingPowerBI] = useState(false);
  const [data, setData] = useState(undefined);
  const [client, setClient] = useState(undefined);

  const loadGroupData = async (groupId) => {
    setLoadingPowerBI(true);

    if (groupId) {
      const reportsResult = await apiGroupReportsGet(groupId);

      setData(reportsResult);
    }

    setLoadingPowerBI(false);
  };

  useEffect(() => {
    const loadData = async (id) => {
      if (id) {
        const result = await apiClientGet(id);
        setClient(result);
        await loadGroupData(result.groupId);
      } else {
        history.push("/secure");
      }

      setLoading(false);
    };

    if (match.params && match.params.clientId) {
      loadData(match.params.clientId);
    } else {
      loadData(null);
    }
  }, [match, history]);

  const columns = [
    { title: "Name", field: "name", defaultSort: "asc" },
    {
      title: "",
      render: (rowData) => (
        <div className="text-right text-nowrap">
          <Link
            to={`/secure/client/${match.params.clientId}/report/${rowData.id}`}
            className="btn btn-sm btn-primary ml-1"
          >
            Assign
          </Link>
        </div>
      ),
      sorting: false,
      width: "150px",
    },
  ];

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <>
          <Row className="mb-2">
            <Col xs={6}>
              <h2>Reports</h2>
            </Col>
            <Col xs={6} className="text-right my-auto"></Col>
          </Row>

          {!loadingPowerBI && (
            <MaterialTable
              icons={tableIcons}
              columns={columns}
              data={data}
              title={client?.companyName}
              options={tableOptions}
            />
          )}
        </>
      )}
    </>
  );
};

export default ReportList;
