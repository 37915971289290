import swal from "@sweetalert/with-react";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import * as Yup from "yup";
import { apiClientsGet } from "../../apis/client";
import { apiUserGet, apiUserSave } from "../../apis/users";
import { InputText, InputToggle } from "../common/inputs";
import { UserContext } from "../../contexts/user-context";

const formSchema = Yup.object().shape({
  displayName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid Email").required("Required"),
});

const UserAdd = ({ match, history }) => {
  const { settings, loadUserSettings } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [clients, setClients] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const loadData = async (id) => {
      if (id) {
        let [clientsResult, userResult] = await Promise.all([apiClientsGet(), apiUserGet(match.params.userId)]);
        // Map client checkbox values
        userResult.clients.forEach((client) => {
          userResult[`client_${client.clientId}`] = true;
        });
        //TODO: Warning Can't perform a React state update on an unmounted component...
        setClients(clientsResult);
        setUser(userResult);
      } else {
        //TODO: Warning Can't perform a React state update on an unmounted component...
        setClients(await apiClientsGet());
        setUser({ id: null, lockoutEnabled: false, isAdmin: false, displayName: "", email: "", clients: [] });
      }
      setLoading(false);
    };

    loadData(match.params.userId);
  }, [match]);

  const getCheckedClients = (values) => {
    const clientIds = [];
    for (let [key, value] of Object.entries(values)) {
      if (key.includes("client_") && value) {
        const parts = key.split("_");
        const id = parts[1];
        clientIds.push(id);
      }
    }
    return clientIds;
  };

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <>
          <h1>Add User</h1>
          <Formik
            initialValues={user}
            validationSchema={formSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setError(undefined);

              if (user.lockoutEnabled !== values.lockoutEnabled) {
                const message = values.lockoutEnabled ? "Disable User?" : "Enable User?";

                const result = await swal({
                  icon: "warning",
                  dangerMode: true,
                  title: message,
                  buttons: true,
                });

                if (!result) {
                  return;
                }
              }

              const checkedClients = getCheckedClients(values);
              if (!values.isAdmin && (!checkedClients || checkedClients.length === 0)) {
                setError("You must select a client if this is not an admin user");
                return;
              }

              var result = await apiUserSave(
                values.id,
                values.email,
                values.displayName,
                values.lockoutEnabled,
                values.isAdmin,
                checkedClients
              );

              if (typeof result === "boolean") {
                //reload if editing current user
                if (settings.userId === values.id) {
                  loadUserSettings();
                }
                history.push("/secure/users");
                return;
              }

              setError(result.map((x) => x.description).join(","));
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="text-left">
                <InputText name="displayName" type="text" label="Name" />
                <InputText name="email" type="text" label="Email Address" />
                <div className="mb-2">
                  <InputToggle label="Disabled" name="lockoutEnabled" />
                </div>
                <div className="mb-2">
                  <InputToggle label="Is Admin?" name="isAdmin" />
                </div>
                <h5 className="pt-4">Clients</h5>

                <p>Please select the Clients that this User will have access to.</p>
                <div className="mb-5">
                  {(!clients || clients.length === 0) && <div>None Available</div>}
                  {clients && clients.map((item, idx) => <InputToggle key={idx} name={`client_${item.id}`} label={item.companyName} />)}
                </div>

                <Button color="primary" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
                {error && <div className="validation-error d-inline ml-2">{error}</div>}
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default UserAdd;
