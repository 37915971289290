import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { apiSendResetPasswordEmail } from "../../apis/auth";
import InputText from "../common/input-text";

const formSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
});

const formDefaults = {
  email: "",
};

const PasswordForgot = () => {
  const [sent, setSent] = useState(false);

  return (
    <Row className="w-100">
      <Col xs={12} md={9} lg={6} className="mx-auto p-2">
        <Card className="w-100">
          <CardBody className="p-4">
            <div className="text-center">
              <img
                src="/images/lumensol-v.png"
                alt="iLumen8 Reporting"
                className="mb-4"
              />
            </div>
            {sent ? (
              <>
                <div className="text-center">
                <h1>Forgotten your password?</h1>
                  <p>
                    Please check your email for instructions on resetting your
                    password.
                  </p>
                  <p>
                    <Link to="/login" className="btn btn-link">
                      Return to Login
                    </Link>
                  </p>
                </div>
              </>
            ) : (
              <>
                <h1>Forgotten your password?</h1>
                <Formik
                  initialValues={formDefaults}
                  validationSchema={formSchema}
                  onSubmit={async (values) => {
                    setSent(true);
                    await apiSendResetPasswordEmail(values.email);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className="text-left">
                      <InputText
                        name="email"
                        type="text"
                        label="Email Address"
                      />

                      <Button
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Send Reset Email
                      </Button>
                    </Form>
                  )}
                </Formik>
                <div className="text-right">
                  <Link to="/login" className="btn btn-link">
                    Return to Login
                  </Link>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordForgot;
