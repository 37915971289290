import MaterialTable from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from "reactstrap";
import { apiUsersGet } from "../../apis/users";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import { UserContext } from "../../contexts/user-context";
import { sortByProp } from "../../helpers/sort";
import { tableIcons, tableOptions } from "../common/material-table-icons";

const UserList = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);
  const { onBehalfOfSet } = useContext(UserContext);

  useEffect(() => {
    const loadData = async () => {
      setData(await apiUsersGet());
      setLoading(false);
    };
    loadData();
  }, []);

  const viewAs = (id) => async () => {
    await onBehalfOfSet(id);
    history.push("/secure/dashboard");
  };

  const columns = [
    {
      title: "Admin",
      field: "isAdmin",
      cellStyle: {
        width: 5,
        maxWidth: 5,
      },
      headerStyle: {
        width: 5,
        maxWidth: 5,
      },
      render: (rowdata) => (
        <span
          style={{
            display: "inline-block",
            width: "16px",
          }}
        >
          {rowdata.isAdmin ? <Check /> : ""}
        </span>
      ),
    },
    {
      title: "Name",
      field: "displayName",
      defaultSort: "asc",
      customSort: sortByProp("displayName"),
    },
    {
      title: "Email",
      field: "email",
      customSort: sortByProp("email"),
    },
    {
      title: "Client",
      field: "clients",
      render: (rowData) => (
        <>
          {rowData.clients.map((c, i) => (
            <Link key={i} to={`/secure/client/${c.clientId}/user/${rowData.id}`} className="d-block">
              {c.companyName}
            </Link>
          ))}
        </>
      ),
      customSort: (a, b) => sortByProp("companyName")(a.clients[0], b.clients[0]),
      customFilterAndSearch: (term, rowData) => {
        return rowData.clients.findIndex((item) => item.companyName.toLocaleLowerCase().includes(term.toLocaleLowerCase())) !== -1;
      },
    },
    {
      title: "Disabled",
      cellStyle: {
        width: 5,
        maxWidth: 5,
      },
      headerStyle: {
        width: 5,
        maxWidth: 5,
      },
      field: "lockoutEnabled",
      hidden: !showDisabled,
      render: (rowdata) => (
        <span
          style={{
            display: "inline-block",
            width: "16px",
          }}
        >
          {rowdata.lockoutEnabled ? <Check /> : ""}
        </span>
      ),
    },
    {
      title: "",
      render: (rowData) => (
        <div className="text-right text-nowrap">
          <UncontrolledButtonDropdown>
            <Link to={`/secure/user/${rowData.id}`} className="btn btn-sm btn-primary d-flex align-items-center">
              Edit
            </Link>
            <DropdownToggle split color="primary" disabled={!rowData.clients?.length} />
            <DropdownMenu>
              <DropdownItem className="text-primary" onClick={viewAs(rowData.id)}>
                View As
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      sorting: false,
      width: "150px",
    },
  ];

  return (
    <>
      {loading ? (
        <span />
      ) : (
        <>
          <Row className="mb-2">
            <Col xs={12} className="text-right my-auto d-flex flex-row justify-content-end align-items-center">
              <CustomInput
                type="switch"
                id="disabledToggle"
                label="Disabled"
                checked={showDisabled}
                onChange={() => setShowDisabled(!showDisabled)}
                className="mr-2"
              />
              <Link to="/secure/user" className="btn btn-sm btn-primary">
                Add User
              </Link>
            </Col>
          </Row>

          <MaterialTable
            icons={tableIcons}
            columns={columns}
            data={data.filter((x) => x.lockoutEnabled === showDisabled)}
            title="Users"
            options={tableOptions}
          />
        </>
      )}
    </>
  );
};

export default UserList;
