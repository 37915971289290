import axios from "axios";

export const apiUsersGet = async (clientId) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/Users",
    params: {
      clientId,
    },
  });

  return result.data;
};

export const apiUserSettingsGet = async (onBehalfOfUserId) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/UserSettings",
    params: { onBehalfOfUserId },
  });

  return result.data;
};

export const apiUserGet = async (id) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/User",
    params: { id },
  });
  return result.data;
};

export const apiUserAssignmentsGet = async (userId, clientId) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/UserAssignments",
    params: { userId, clientId },
  });
  return result.data;
};

export const apiLandingGet = async (userId, clientId, groupId) => {
  const result = await axios({
    method: "get",
    url: "/api/v1/Landing",
    params: { userId, clientId, groupId },
  });
  return result.data;
};

export const apiUserAssignmentsPatch = async (userId, clientId, dashboardIds, reportIds, emailReportIds) => {
  try {
    await axios({
      method: "patch",
      url: "/api/v1/UserAssignments",
      data: { userId, clientId, dashboardIds, reportIds, emailReportIds },
    });

    return true;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return err.response.data;
    }

    console.error(err);
    return false;
  }
};

export const apiUserSave = async (id, email, displayName, lockoutEnabled, isAdmin, clients) => {
  try {
    await axios({
      method: "post",
      url: "/api/v1/User",
      data: { id, email, displayName, lockoutEnabled, isAdmin, clients },
    });

    return true;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return err.response.data;
    }

    console.error(err);
    return false;
  }
};
