import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import CenteredContainer from "../containers/centered-container";

const ClientError = () => {
  return (
    <CenteredContainer>
      <Row className="w-100">
        <Col xs={12} md={9} lg={6} className="mx-auto p-2">
          <Card className="w-100">
            <CardBody className="p-4">
              <div className="text-center">
                <img
                  src="/images/lumensol-v.png"
                  alt="iLumen8 Reporting"
                  className="mb-4"
                />
                <h1 style={{color:"#ff0000"}}>Client Error (400)</h1>
                <p>Something went wrong. Please contact us if the problem persists.</p>
                <Link to="/" className="btn btn-link">
                  Return to Home
                </Link>{" "}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </CenteredContainer>
  );
};
export default ClientError;
