import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "../auth/login";
import NewUser from "../auth/new-user";
import PasswordForgot from "../auth/password-forgot";
import PasswordReset from "../auth/password-reset";
import CenteredContainer from "../containers/centered-container";
import NotFound from "../not-found";

const PreauthLayout = () => {
  return (
    <CenteredContainer>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/password-forgot" exact component={PasswordForgot} />
        <Route path="/password-reset" exact component={PasswordReset} />
        <Route path="/new-user" exact component={NewUser} />
        <Route component={NotFound} />
     </Switch>
    </CenteredContainer>
  );
};

export default withRouter(PreauthLayout);
