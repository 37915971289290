import React, { useContext, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Collapse, Nav, NavItem, Navbar, NavbarToggler } from "reactstrap";
import { ReactComponent as EyeSlash } from "../../assets/images/eye-slash.svg";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { UserContext } from "../../contexts/user-context";
import DisplayLogo from "../common/display-logo";

const MainNav = ({ history }) => {
  const [eyeHovered, setEyeHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { settings, onBehalfOfClear, contextBrand, contextClients } = useContext(UserContext);

  const toggle = () => setIsOpen(!isOpen);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const onBehalfOfClearHandle = () => {
    onBehalfOfClear();
    history.push("/secure/users");
  };

  return (
    settings.userId && (
      <>
        <Navbar
          expand="md"
          dark
          style={{
            backgroundColor: contextBrand.backgroundColour,
          }}
        >
          <DisplayLogo image={contextBrand.logoBase64Image} companyName={contextBrand.companyName} />
          <NavbarToggler onClick={toggle} style={{ backgroundColor: contextBrand.colour }}>
            <svg height="20" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill={`${contextBrand.backgroundColour}`}
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
              ></path>
            </svg>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {settings.isAdmin ? (
                <>
                  {settings.onBehalfOfSettings?.name && (
                    <NavItem className="my-auto">
                      <Button
                        size="sm"
                        className="my-2 my-md-0 mr-md-2 border-0"
                        style={{ backgroundColor: contextBrand.colour, padding: "8px", fontSize: "16px" }}
                        title={`Stop viewing as '${settings.onBehalfOfSettings?.name}'`}
                        onClick={onBehalfOfClearHandle}
                        onMouseEnter={() => {
                          setEyeHovered(true);
                        }}
                        onMouseLeave={() => {
                          setEyeHovered(false);
                        }}
                      >
                        <span
                          style={{
                            color: contextBrand.backgroundColour,
                          }}
                        >
                          <span
                            style={{
                              color: contextBrand.backgroundColour,
                              display: "inline-block",
                              width: "16px",
                            }}
                          >
                            {eyeHovered ? <EyeSlash /> : <Eye />}
                          </span>
                          <span className="ml-1">{settings.onBehalfOfSettings?.name}</span>
                        </span>
                      </Button>
                    </NavItem>
                  )}
                  {settings.onBehalfOfSettings?.userId ? (
                    <NavItem>
                      <NavLink to="/secure/dashboard" className="nav-link px-2" style={{ color: contextBrand.colour }} onClick={closeMenu}>
                        User Home
                      </NavLink>
                    </NavItem>
                  ) : (
                    <>
                      {contextClients?.length > 0 && (
                        <NavItem>
                          <NavLink
                            to="/secure/dashboard"
                            className="nav-link px-2"
                            style={{ color: contextBrand.colour }}
                            onClick={closeMenu}
                          >
                            Dashboard
                          </NavLink>
                        </NavItem>
                      )}

                      <NavItem>
                        <NavLink to="/secure/clients" className="nav-link px-2" style={{ color: contextBrand.colour }} onClick={closeMenu}>
                          Clients
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink to="/secure/users" className="nav-link px-2" style={{ color: contextBrand.colour }} onClick={closeMenu}>
                          Users
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                </>
              ) : (
                <NavItem>
                  <NavLink to="/secure" className="nav-link px-2" style={{ color: contextBrand.colour }} onClick={closeMenu}>
                    Home
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink to="/logout" className="nav-link px-2" style={{ color: contextBrand.colour }} onClick={closeMenu}>
                  Log Out
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </>
    )
  );
};

export default withRouter(MainNav);
