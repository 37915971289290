import axios from "axios";

export const apiGroupsGet = async () => {
  const result = await axios({
    method: "get",
    url: "/api/v1/PowerBi/Groups",
  });

  return result.data;
};

export const apiGroupReportsGet = async (groupId) => {
  const result = await axios({
    method: "get",
    url: `/api/v1/PowerBi/Groups/${groupId}/reports`,
  });

  return result.data;
};

export const apiGroupDashboardsGet = async (groupId) => {
  const result = await axios({
    method: "get",
    url: `/api/v1/PowerBi/Groups/${groupId}/dashboards`,
  });

  return result.data;
};

export const apiGroupReportEmbedTokenGet = async (groupId, reportId, userId, clientId) => {
  const result = await axios({
    method: "post",
    url: `/api/v1/PowerBI/Groups/${groupId}/reports/${reportId}/GenerateToken`,
    data: { userId, clientId },
  });

  return result.data;
};

export const apiGroupDashboardEmbedTokenGet = async (groupId, dashboardId, userId, clientId) => {
  const result = await axios({
    method: "post",
    url: `/api/v1/PowerBI/Groups/${groupId}/Dashboards/${dashboardId}/GenerateToken`,
    data: { userId, clientId },
  });

  return result.data;
};

export const apiPing = async () => {
  await axios({
    method: "get",
    url: `/api/v1/Ping`,
  });
};

export const apiExportReports = async () => {
  try {
    const result = await axios({
      method: "post",
      url: "/api/v1/ExportReports",
      data: { IgnoreSchedule: true },
    });
    return result.data;
  } catch (err) {
    console.error(err);
    return false;
  }
};
