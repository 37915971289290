import React, { createContext, useCallback, useEffect, useState } from "react";
import { apiUserSettingsGet } from "../../apis/users";
//import useEffectDebugger from "../../effect-debugger";

export const Context = createContext({});

//TODO: typescript
const defaultSettings = {
  userId: "",
  name: "",
  isAdmin: false,
  suspendAfterMinutes: 5,
  suspendForbiddenSchedules: [],
  clients: [],
  onBehalfOfSettings: undefined,
};

const defaultBrand = {
  companyName: "Lumensol Admin",
  colour: "#01689a",
  backgroundColour: "#FFF",
  logoBase64Image: undefined,
};

export const Provider = ({ children }) => {
  const [settings, setSettings] = useState(defaultSettings);
  const [contextClients, setContextClients] = useState(undefined);
  const [contextClientId, setContextClientId] = useState(
    localStorage.getItem("clientId") ? parseInt(localStorage.getItem("clientId")) : undefined
  );
  const [contextClient, setContextClient] = useState(undefined);
  const [contextBrand, setContextBrand] = useState(defaultBrand);

  useEffect(() => {
    if (contextClientId) {
      localStorage.setItem("clientId", contextClientId);
    } else {
      localStorage.removeItem("clientId");
    }
  }, [contextClientId]);

  useEffect(() => {
    if (settings.onBehalfOfSettings) {
      setContextClients(settings.onBehalfOfSettings.clients);
    } else {
      setContextClients(settings.clients);
    }
  }, [settings.clients, settings.onBehalfOfSettings]);

  useEffect(() => {
    if (contextClients && contextClientId) {
      setContextClient(contextClients.find((c) => c.clientId === contextClientId));
    } else {
      setContextClient(undefined);
    }
  }, [contextClients, contextClientId]);

  useEffect(() => {
    if (contextClient) {
      setContextBrand({
        companyName: contextClient.companyName,
        colour: contextClient.colour,
        backgroundColour: contextClient.backgroundColour,
        logoBase64Image: contextClient.logoBase64Image,
      });
    } else {
      setContextBrand(defaultBrand);
    }
  }, [contextClient]);

  const loadUserSettings = useCallback(async () => {
    var result = await apiUserSettingsGet();
    setSettings((prev) => {
      return {
        ...prev,
        userId: result.userId,
        name: result.displayName,
        isAdmin: result.role === "Admin",
        suspendAfterMinutes: result.suspendAfterMinutes || defaultSettings.suspendAfterMinutes,
        suspendForbiddenSchedules: result.suspendForbiddenSchedules || defaultSettings.suspendForbiddenSchedules,
        clients: result.clients || defaultSettings.clients,
      };
    });
  }, []);

  useEffect(() => {
    loadUserSettings();
  }, [loadUserSettings]);

  const onBehalfOfSet = async (userId) => {
    if (settings.isAdmin) {
      var result = await apiUserSettingsGet(userId);
      setSettings((prev) => {
        return {
          ...prev,
          onBehalfOfSettings: {
            userId: result.userId,
            name: result.displayName,
            isAdmin: result.role === "Admin",
            clients: result.clients || defaultSettings.clients,
          },
        };
      });
    }
  };

  const onBehalfOfClear = useCallback(() => {
    if (settings.isAdmin) {
      setContextClientId(undefined);
      setSettings((prev) => {
        return { ...prev, onBehalfOfSettings: undefined };
      });
    }
  }, [settings]);

  const isWithinForbiddenPeriod = useCallback(() => {
    //TODO: Test before settings loaded
    const d = new Date();
    const day = d.getUTCDay();
    const hourUtc = d.getUTCHours();
    const schedules = settings?.suspendForbiddenSchedules;
    if (!schedules) {
      return true;
    }
    return schedules.some((s) => day >= s.dayFrom && day <= s.dayTo && hourUtc >= s.hourFromUtc && hourUtc <= s.hourToUtc);
  }, [settings]);

  const IdleWarningMillisecondsGet = useCallback(() => {
    if (isWithinForbiddenPeriod()) {
      return 0;
    }
    return (settings.suspendAfterMinutes - 1) * 60 * 1000;
  }, [settings.suspendAfterMinutes, isWithinForbiddenPeriod]);

  const IdleRedirectMillisecondsGet = useCallback(() => {
    if (isWithinForbiddenPeriod()) {
      return 0;
    }
    return (settings.suspendAfterMinutes - 0.5) * 60 * 1000;
  }, [settings.suspendAfterMinutes, isWithinForbiddenPeriod]);

  const userContext = {
    onBehalfOfSet,
    onBehalfOfClear,
    IdleWarningMillisecondsGet,
    IdleRedirectMillisecondsGet,
    settings,
    setContextClientId,
    contextClientId,
    contextClients,
    contextClient,
    contextBrand,
    loadUserSettings,
  };

  return <Context.Provider value={userContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
