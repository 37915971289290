import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../../contexts/user-context";

const AdminRoute = (props) => {
  const { settings, setContextClientId, onBehalfOfClear } = useContext(UserContext);

  useEffect(() => {
    setContextClientId(undefined);
  }, [setContextClientId, settings.onBehalfOfSettings, onBehalfOfClear]);

  if (!settings.userId) {
    return null;
  }

  return settings.isAdmin ? <Route {...props} /> : <Redirect to="/secure" />;
};

export default AdminRoute;
