import React from "react";

const CenteredContainer = ({ children }) => {
  return (
  <div className="app-center">
      {children}
  </div>
  );
};

export default CenteredContainer;
