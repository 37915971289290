import { useField } from "formik";
import React from "react";
import { ChromePicker } from "react-color";
import { FormGroup } from "reactstrap";

const InputColour = ({ label, ...props }) => {
  const [field, meta, helper] = useField(props);

  const handleChange = (c) => {
    helper.setValue(c.hex);
  };

  return (
    <FormGroup>
      <label htmlFor={field.name}>{label}</label>
      <ChromePicker color={field.value} onChange={(c) => handleChange(c)} width="90%" />
      {meta.touched && meta.error && <div className="validation-error">{meta.error}</div>}
    </FormGroup>
  );
};

export default InputColour;
