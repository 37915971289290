import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Row className="w-100">
      <Col xs={12} md={9} lg={6} className="mx-auto p-2">
        <Card className="w-100">
          <CardBody className="p-4">
            <div className="text-center">
              <img
                src="/images/lumensol-v.png"
                alt="iLumen8 Reporting"
                className="mb-4"
              />
              <h1>Not Found</h1>
              <p>Sorry, we couldn’t find that page</p>
              <Link to="/" className="btn btn-link">
                Return to Home
              </Link>{" "}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default NotFound;
