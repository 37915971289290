import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomInput } from "reactstrap";
import { apiLandingGet } from "../../apis/users";
import { ReactComponent as LumenGauge } from "../../assets/images/lumen-gauge.svg";
import { UserContext } from "../../contexts/user-context";
import { sortByProp } from "../../helpers/sort";

const Dashboard = () => {
  const { contextClientId, contextClients, contextClient, setContextClientId } = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const [clients, setClients] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const [clientId, setClientId] = useState(undefined);
  const [dashboards, setDashboards] = useState(undefined);
  const [reports, setReports] = useState(undefined);

  const [groups, setGroups] = useState(undefined);

  useEffect(() => {
    setClients(contextClients);
    setClientId(contextClientId);

    if (clients && clients.length > 0 && !contextClient) {
      setClient(clients[0]);
      setContextClientId(clients[0].clientId);
    }
  }, [contextClients, contextClientId, contextClient, setContextClientId, clients]);

  useEffect(() => {
    if (clients && clientId) {
      setClient(clients.find((c) => c.clientId === parseInt(clientId)));
    }
  }, [clients, clientId]);

  useEffect(() => {
    const loadData = async (client) => {
      setLoading(true);
      const result = await apiLandingGet(client.userId, client.clientId, client.groupId);
      setReports(result.reports);

      if (result.reports) {
        setGroups([...new Set(result.reports.map((report) => report.displayGroup))]);
      }
      setDashboards(result.dashboards);
      setLoading(false);
    };
    if (client) {
      loadData(client);
    }
  }, [client]);

  return (
    <>
      {clients?.length > 1 && (
        <CustomInput
          type="select"
          name="clients"
          id="clients"
          className="shadow-sm"
          onChange={(e) => {
            setClientId(e.target.value);
            setContextClientId(parseInt(e.target.value));
          }}
          defaultValue={clientId}
        >
          {clients.sort(sortByProp("companyName")).map((item, idx) => (
            <option value={item.clientId} key={idx}>
              {item.companyName}
            </option>
          ))}
        </CustomInput>
      )}

      {!loading && (
        <>
          {dashboards && dashboards.length > 0 && (
            <>
              <h2 className="mt-5">Dashboards</h2>
              <div className="d-flex flex-wrap align-items-stretch">
                {dashboards.map((item, index) => (
                  <div className="pr-2 pb-2" key={index}>
                    <Link
                      to={`/secure/group-dashboard/${item.id}`}
                      style={{ width: 300, backgroundColor: "#222A55" }}
                      className="d-block h-100 pb-2 pr-2 rounded text-decoration-none text-center"
                      key={index}
                    >
                      <div style={{ color: "#CB9905", padding: "2em" }}>
                        <LumenGauge style={{ maxWidth: "100px" }} />
                      </div>
                      <h4 className="text-white">{item.displayName}</h4>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}

          {groups &&
            groups.length > 0 &&
            groups.map((group, index) => {
              const groupReports = reports.filter((report) => report.displayGroup === group);
              return (
                <div key={index}>
                  {groupReports && groupReports.length > 0 && (
                    <>
                      <h2 className="mt-5">{group}</h2>
                      <div className="d-flex flex-wrap align-items-stretch">
                        {groupReports.map((item, index) => (
                          <div className="pr-2 pb-2" key={index}>
                            <Link
                              to={`/secure/group-report/${item.id}`}
                              style={{ width: 300, backgroundColor: "#CB9905" }}
                              className="d-block h-100 pb-2 pr-2 rounded text-decoration-none text-center"
                              key={index}
                            >
                              <div style={{ color: "#222A55", padding: "2em" }}>
                                <LumenGauge style={{ maxWidth: "100px" }} />
                              </div>
                              <h4 className="text-white">{item.simpleName}</h4>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default Dashboard;
