import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";
import { apiClientGet, apiClientUpdate, apiDomainsGet } from "../../apis/client";
import { apiGroupDashboardsGet, apiGroupReportsGet, apiGroupsGet } from "../../apis/power-bi";
import { logoHeight } from "../../config";
import DisplayLogo from "../common/display-logo";
import { InputColour, InputFile, InputSelect, InputText, InputToggle } from "../common/inputs";

const formSchema = Yup.object().shape({
  companyName: Yup.string().required("Required"),
  groupId: Yup.string().required("Required"),
});

const formDefaults = {
  companyName: "",
  colour: "#fff",
  backgroundColour: "#00f",
  image: undefined,
  groupId: "",
  domainId: 1,
};

const ClientEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [loadingPowerBI, setLoadingPowerBI] = useState(false);
  const [id, setId] = useState(undefined);
  const [data, setData] = useState(formDefaults);
  const [groups, setGroups] = useState([]);
  const [domains, setDomains] = useState([]);
  const [dashboards, setDashboards] = useState(null);
  const [reports, setReports] = useState(null);
  const [error, setError] = useState(undefined);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);

  const loadGroupData = async (groupId) => {
    setLoadingPowerBI(true);

    setDashboards(null);
    setReports(null);

    if (groupId) {
      let [dashboardsResult, reportsResult] = await Promise.all([apiGroupDashboardsGet(groupId), apiGroupReportsGet(groupId)]);

      setDashboards(dashboardsResult);
      setReports(reportsResult);
    }

    setLoadingPowerBI(false);
  };

  useEffect(() => {
    const loadData = async (id) => {
      setDomains(await apiDomainsGet());

      if (id) {
        const result = await apiClientGet(id);

        if (result.logoBase64Image) {
          result.image = result.logoBase64Image;
          delete result.logoBase64Image;
        }

        result.dashboardIds.forEach((dashboardId) => {
          result[`dashboard_${dashboardId}`] = true;
        });

        result.reportIds.forEach((reportId) => {
          result[`report_${reportId}`] = true;
        });

        loadGroupData(result.groupId);

        setData(result);
      } else {
        setData(formDefaults);
      }

      setGroups(await apiGroupsGet());

      setLoading(false);
    };

    if (match.params && match.params.id) {
      loadData(match.params.id);
      setId(match.params.id);
    } else {
      loadData(undefined);
      setId(undefined);
      setLoading(false);
    }
  }, [match]);

  const onLogoUpload = () => {
    setImageUploaded(true);
  };

  const onLogoClear = () => {
    setImageRemoved(true);
    setImageUploaded(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const groupChangeHandle = (values) => async (e) => {
    // Remove values for previous group
    for (let key of Object.keys(values)) {
      if (key.includes("_")) {
        delete values[key];
      }
    }

    const groupId = e.target.value;
    await loadGroupData(groupId);
  };

  const getDashboardAndReports = (values) => {
    const dashboardIds = [];
    const reportIds = [];

    for (let [key, value] of Object.entries(values)) {
      if (key.includes("_") && value) {
        const parts = key.split("_");

        switch (parts[0]) {
          case "dashboard":
            dashboardIds.push(parts[1]);
            break;
          case "report":
            reportIds.push(parts[1]);
            break;
          default:
            break;
        }
      }
    }

    return { dashboardIds, reportIds };
  };

  return (
    <>
      {loading ? (
        <span />
      ) : (
          <>
            <h1>{id ? "Edit" : "Add"} Client</h1>
            <Formik
              initialValues={data}
              validationSchema={formSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setError(undefined);

                let { dashboardIds, reportIds } = getDashboardAndReports(values);

                let base64 = null;
                if (imageUploaded) {
                  base64 = await toBase64(values.image);
                }

                var result = await apiClientUpdate(id, values.companyName, values.colour, values.backgroundColour, base64, imageRemoved, values.groupId, values.domainId, dashboardIds, reportIds);

                if (typeof result === "boolean") {
                  history.push("/secure/clients");
                  return;
                }

                setError(result.errors.map((x) => x.errorMessage).join(","));
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form className="text-left">
                  <InputText name="companyName" type="text" label="Company" />
                  <InputSelect name="domainId" items={domains} label="Domain" />

                  <h5>Power BI Permissions</h5>
                  <InputSelect name="groupId" items={groups} label="Workspace" optional optionalValue="" onChange={groupChangeHandle(values)} />
                  {id && 
                    <div className="alert alert-danger">
                      Warning: Changing workspace for the client will remove users access to reports and dashboards in this group.
                    </div>
                  }

                  {values.groupId && (
                    <>
                      {!loadingPowerBI &&
                          <>
                            <p>Please select the Dashboards and Reports that all users of this Client will have access to.</p>
                            <div className="mb-2">
                              <Row>
                                <Col>
                                  <u>Dashboard</u>
                                </Col>
                                <Col xs="2" md={1} className="text-center">
                                  View
                            </Col>
                              </Row>
                              {(!dashboards || dashboards.length === 0) && <div>None Available</div>}
                              {dashboards &&
                                dashboards.map((item, idx) => (
                                  <Row key={idx}>
                                    <Col>{item.displayName}</Col>
                                    <Col xs="2" md={1} className="text-center">
                                      <InputToggle name={`dashboard_${item.id}`} />
                                    </Col>
                                  </Row>
                                ))}
                              <Row className="mt-2">
                                <Col>
                                  <u>Reports</u>
                                </Col>
                                <Col xs="2" md={1} className="text-center">
                                  View
                            </Col>
                              </Row>
                              {(!reports || reports.length === 0) && <div>None Available</div>}
                              {reports &&
                                reports.map((item, idx) => (
                                  <Row key={idx}>
                                    <Col>{item.name}</Col>
                                    <Col xs="2" md={1} className="text-center">
                                      <InputToggle name={`report_${item.id}`} />
                                    </Col>
                                  </Row>
                                ))}
                            </div>
                          </>
                        }
                    </>
                  )}

                  <h5>Branding</h5>
                  <div>
                    <label>Navigation Preview</label>
                    <div style={{ color: values.colour, backgroundColor: values.backgroundColour }} className="p-2 shadow-sm">
                      <div style={{ height: logoHeight }} className="d-flex align-items-center">
                        <DisplayLogo image={values.image} />
                        {!values.image &&
                          <span className="py-auto" style={{ fontSize: 20 }}>
                            {values.companyName}
                          </span>
                        }
                        <span className="py-auto ml-auto mr-2" style={{ fontSize: 16 }}>
                          Log Out
                      </span>
                      </div>
                    </div>
                  </div>

                  <FormGroup className="mt-2">
                    <Row>
                      <Col xs={6} md={4} lg={4} xl={3}>
                        <InputColour name="colour" label="Colour" />
                      </Col>
                      <Col xs={6} md={4} lg={4} xl={3}>
                        <InputColour name="backgroundColour" label="Background" />
                      </Col>
                      <Col xs={6} md={4} lg={4} xl={3}>
                        <InputFile name="image" label="Logo" onClear={onLogoClear} onUpload={onLogoUpload} />
                      </Col>
                    </Row>
                  </FormGroup>

                  <Button color="primary" type="submit" disabled={isSubmitting}>
                    Save
                </Button>
                  {error && <div className="validation-error d-inline ml-2">{error}</div>}
                </Form>
              )}
            </Formik>
          </>
        )}
    </>
  );
};

export default ClientEdit;
